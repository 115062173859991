@font-face {
  font-family: 'Maledpan';
  src: url('./assets/fonts/Maledpan-Regular.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Maledpan';
  src: url('./assets/fonts/Maledpan-Light.otf') format('opentype');
  font-weight: lighter;
  font-display: swap;
}

@font-face {
  font-family: 'Maledpan';
  src: url('./assets/fonts/Maledpan-Bold.otf') format('opentype');
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Anuphan';
  src: url('./assets/fonts/Anuphan-Regular.ttf') format('truetype'), url('./assets/fonts/Anuphan-Regular.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Anuphan';
  src: url('./assets/fonts/Anuphan-Medium.ttf') format('truetype'), url('./assets/fonts/Anuphan-Medium.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Anuphan';
  src: url('./assets/fonts/Anuphan-SemiBold.ttf') format('truetype'), url('./assets/fonts/Anuphan-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Anuphan';
  src: url('./assets/fonts/Anuphan-Bold.ttf') format('truetype'), url('./assets/fonts/Anuphan-Bold.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
}